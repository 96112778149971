import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components";
//import Credit from "./styled/credit";
import PulseNotice from "./PulseNotice";

const Video = ({ 
  src, 
  muted = undefined, 
  loop = undefined, 
  autoplay = undefined, 
  maxWidth, 
  onEnded, 
  customPadding, 
  hasSound = undefined, 
  preload = undefined, 
  credit = undefined,
  soundControl = undefined,
  hideLoading = undefined,
  fitToContainer = false
}) => {


  const videoRef = useRef();
  const preloadRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [sound, setSound] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadable, setIsLoadable] = useState(false);
  const [canPlay, setCanPlay] = useState(false);

  const [source, setSource] = useState(src);

  
  
  useEffect(() => {
    //setSource(src);
    if ( src !== videoRef.current.src ) {
      videoRef.current.src = src;
    }
  },[src]);
  /*
  useEffect(() => {
    if ( source !== videoRef.current.src && isLoadable ) {
      videoRef.current.src = source;
    }
  }, [source])
  */
  useEffect(() => {
    if ( ! isLoaded && isLoadable ) {
      videoRef.current.src = src;
      setIsLoaded(true);
    }
  }, [isLoadable]);
  
  
  useEffect(() => {
    if ( ! preloadRef.current ) return;

    let observer;
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: [0,1]
    };
    observer = new IntersectionObserver((entries, observer) => {
      if ( entries[0].intersectionRatio > 0 ) {
        setIsLoadable(true);
        observer.disconnect();
      }
    }, options);

    observer.observe(preloadRef.current);

    return () => observer?.disconnect();

  }, []);
  

  /*
  function handleIntersect(entries, observer) {
    ( entries[0].intersectionRatio > 0 ) ? setIsActive(true) : setIsActive(false);
  }

  function setupObserver(el) {
    let observer;
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: [0,1]
    };
    observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(el);
    return observer;
  }

  useEffect(() => {
    if ( ! videoRef.current ) return;

    const observer = setupObserver( videoRef.current );

    return () => observer?.disconnect();
  }, []);
  */
  
  const toggleSound = () => {
    if ( isPlaying ) {
      videoRef.current.muted = ! videoRef.current.muted;
      setSound( ! videoRef.current.muted)
    }
  }
  

  return (
    <StyledVideo 
      maxWidth={maxWidth || undefined} 
      customPadding={customPadding || undefined}
      //className={isPlaying ? 'is-playing' : undefined}
      fitToContainer={fitToContainer}
    >
      <StyledPreload ref={preloadRef} />
      <div>
        <video 
          ref={videoRef}
          src={preload || isLoadable ? src : ''} 
          //src={src ? withPrefix(src) : ''} 
          playsInline="playsinline"
          autoPlay={autoplay || undefined}
          muted={muted || autoplay ? "muted" : undefined}
          onEnded={onEnded || undefined}
          loop={loop}
          preload={preload}
          //onPlay={() => setIsPlaying(true)}
          //onPause={() => setIsPlaying(false)}
          onCanPlay={() => setCanPlay(true)}
        />
        {/*<StyledPlayPause onClick={handlePlayPause}>
          <span>
            {isPlaying ? <Pause /> : <Play />}
          </span>
        </StyledPlayPause>*/}
        {! canPlay && ! hideLoading && (
          <StyledLoading>
            <PulseNotice>loading</PulseNotice>
          </StyledLoading>
        )}
      </div>
      {/*credit && (
        <Credit>{credit}</Credit>
      )*/}
      {hasSound && (
        <StyledSoundButton onClick={toggleSound} controlPosition={soundControl}>
          {sound && <SoundOn />}
          {! sound && <SoundOff />}
        </StyledSoundButton>
      )}
    </StyledVideo>
  )
};

export default Video;

const StyledPreload = styled.span`
  position: absolute;
  display: block;
  height: 200vh;
  width: 1px;
  bottom: 0;
`;


const StyledLoading = styled.span`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-light);
  background-color: rgba(23,23,21,0.6);
`;

/*
const StyledPlayPause = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    position: relative;
    display: block;
    height: 3em;
    width: 3em;
    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .is-playing & {
    opacity: 0;
  }
  .can-hover .is-playing &:hover {
    opacity: 1;
  }
`*/

const StyledSoundButton = styled.span`
  position: relative;
  display: block;
  z-index: 2;
  margin: 1em auto 0;
  height: 2em;
  width: 2em;
  opacity: 0.7;
  transition: opacity 0.2s ease;
  .can-hover &:hover,
  &:active {
    opacity: 1;
  }
  svg {
    display: block;
    width: 100%;
    height: auto;
    polygon,
    path,
    rect {
    }
  }
  ${props => props.controlPosition == 'right' && css`
    @media ( max-width: 48em ) {
      position: absolute;
      left: 50%;
      bottom: calc(100% + 1.25em);
      margin: 0 0 0 -1em;
    }
    @media ( min-width: 48em ) {
      position: absolute;
      top: 50%;
      left: calc(100% + 1.25em);
      margin: -1.25em 0 0;
    }
  `}
`;

const SoundOff = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.22 32.22"><path d="M11.39,12.87,17,8.17V24.06l-5.62-4.71H7.93V12.87Zm11.79.72-1.8,1.8-1.8-1.8-.73.72,1.8,1.8-1.8,1.8.73.73,1.8-1.8,1.8,1.8.72-.73-1.8-1.8,1.8-1.8Zm9,2.52A16.11,16.11,0,1,1,16.11,0,16.12,16.12,0,0,1,32.22,16.11Zm-1,0A15.09,15.09,0,1,0,16.11,31.2,15.11,15.11,0,0,0,31.2,16.11Z" fill="#fff"/></svg>
);

const SoundOn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.22 32.22"><rect x="20.63" y="11.28" width="1.02" height="9.66" fill="#fff"/><polygon points="7.93 12.87 7.93 15.77 7.93 16.46 7.93 19.35 11.39 19.35 17.01 24.06 17.01 8.16 11.39 12.87 7.93 12.87" fill="#fff"/><rect x="18.39" y="13.33" width="1.02" height="5.57" fill="#fff"/><path d="M16.11,0A16.11,16.11,0,1,0,32.22,16.11,16.13,16.13,0,0,0,16.11,0Zm0,31.2A15.09,15.09,0,1,1,31.2,16.11,15.11,15.11,0,0,1,16.11,31.2Z" fill="#fff"/><rect x="22.88" y="9.11" width="1.02" height="14.01" fill="#fff"/></svg>
);

/*
const Play = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.22 32.22"><path d="M11.94,24V8.24l11.32,7.87Zm20.28-7.87A16.11,16.11,0,1,1,16.11,0,16.12,16.12,0,0,1,32.22,16.11Zm-1,0A15.09,15.09,0,1,0,16.11,31.2,15.11,15.11,0,0,0,31.2,16.11Z" fill="#fff"/></svg>
);

const Pause = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.22 32.22"><path d="M16.11,0A16.11,16.11,0,1,0,32.22,16.11,16.13,16.13,0,0,0,16.11,0Zm0,31.2A15.09,15.09,0,1,1,31.2,16.11,15.11,15.11,0,0,1,16.11,31.2Zm-2-8.41H10.84V9.43h3.27Zm7.27,0H18.12V9.43h3.26Z" fill="#fff"/></svg>
)
*/


const StyledVideo = styled.div`
  position: relative;
  width: 100%;
  //max-width: ${props => props.maxWidth ? props.maxWidth : `var(--text-max-width)`};
  margin: 0 auto;
  ${props => props.fitToContainer && css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `}
  div {
    position: relative;
    width: 100%;
    background-color: black;
    padding-bottom: ${props => props.customPadding ? props.customPadding : '56.25%'};
    padding-bottom: ${props => props.customPadding ? props.customPadding : '65%'};
    overflow: hidden;
    ${props => ! props.customPadding && css`
      @media ( max-width: 40em ) {
        padding-bottom: 70%;
      }
    `}
    ${props => props.fitToContainer && css`
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 0;
    `}
  }
  video {
    position: absolute;
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;
