import React, { useLayoutEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import StyledText from "../components/StyledText";
import Arrow from "../components/Arrow";
import MuralThumb from "../components/MuralThumb";
import ReactMarkdown from "react-markdown";
import Meta from "../components/Meta";


const IndexPage = ({ data }) => {
  const [shuffledMurals, setShuffledMurals] = useState([]);

  const firstMural = data.directus.murals[0];

  useLayoutEffect(() => {
    setShuffledMurals(data.directus.murals.sort((a, b) => 0.5 - Math.random()));
  },[]);


  return (
    <>
      <Meta
        title="The Outlaw Ocean Mural Project"
        image="/the-outlaw-ocean-mural-project-cover.jpg"
      />
      <StyledIndex>
        <StyledText>
          <p>EXPLORE &mdash; All murals</p>
          <Arrow />
        </StyledText>
        <ul>
          {shuffledMurals.map((item, index) => (
            <li key={`index-mural-${index}`}>
              <MuralThumb item={item}  />
            </li>
          ))}
        </ul>
        <StyledText>
          <p>Murals added monthly</p>
        </StyledText>
      </StyledIndex>
    </>
  );
};

export default IndexPage;

const StyledIndex = styled.div`
  padding: 3em 0;
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 3em 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  @media ( min-width: 60em ) {
    padding: 6em 0;
    grid-template: auto / 1fr 1fr;
    grid-gap: 6em 0;
    > ul {
      grid-column: 1 / span 2;

    }
    ${StyledText} {
      grid-column: 2;
    }
  }
`;

export const query = graphql`
  query {
    directus {
      murals(
        filter: {
          status: {_eq: "published"}
        }
      ) {
        artists {
          mural_artists_id {
            id
            name
            slug
          }
        }
        country
        image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 2048, quality: 80)
            }
          }
        }
        slug
        override_artist_slug
        override_artist_name
        title
      }
    }
  }
`;