
import React from "react";
import styled from "styled-components";

const Arrow = () => (
  <StyledArrow xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.99 7.51">
    <polygon points="5.24 0 4.53 0.71 7.08 3.26 0 3.26 0.01 4.26 7.08 4.26 4.53 6.8 5.24 7.51 8.99 3.75 5.24 0" />
  </StyledArrow>
);

export default Arrow;

const StyledArrow = styled.svg`
  position: relative;
  display: inline-block;
  height: 1em;
  width: 0.875em;
  margin-left: 0.125em;
  margin-top: 2em;
  transform: rotate(90deg) translate3d(0,50%,0);
  polygon {
    fill: currentColor;
  }
`;