import styled from "styled-components";

const StyledText = styled.div`
  > * {
    font-size: var(--text-size);
    font-weight: var(--text-weight);
    margin: 1em 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  h2 {
    text-transform: uppercase;
    span {
      text-transform: none;
    }
  }
  a[href] {
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 0.1em;
    .can-hover &:hover,
    &:active {
      opacity: 0.5;
    }
  }
`;
export default StyledText;