import React from "react"
import styled, { keyframes } from "styled-components"

const PulseNotice = ({ children }) => (
  <StyledPulseNotice><span>{children}</span></StyledPulseNotice>
);

export default PulseNotice;


const moveLeft = keyframes`
  0%,
  100% {
    transform: translate3d(0,0,0);
    //filter: blur(0);
  }
  55% {
    transform: translate3d(-100%,0,0);
   // filter: blur(1px);
  }
`
const moveRight = keyframes`
  0%,
  100% {
    transform: translate3d(0,0,0);
    //filter: blur(0);
  }
  55% {
    transform: translate3d(100%,0,0);
    //filter: blur(1px);
  }
`
const pulse = keyframes`
  0%,
  100% {
    opacity: 1;
  }
  55% {
    opacity: 0.7;
  }
`;

const StyledPulseNotice = styled.span`
  position: relative;
  display: block;
  text-align: center;
  font-size: var(--text-size);
  font-weight: var(--text-weight);
  text-transform: uppercase;
  transform: translate3d(0,0,0) scale(0.7);
  transition: opacity 0.2s ease, transform 0.3s ease;
  transform: translate3d(0,0,0) scale(1);
  //animation ${pulse} 2s ease-in-out infinite;
  span {
    //animation: ${pulse} 2s ease-in-out infinite;
    //letter-spacing: 0.05em;
  }
  &::before,
  &::after {
    position: relative;
    top: 0;
  }
  &::before {
    content: '- ';
    display: inline-block;
    margin-right: 0.2em;
    animation: ${moveLeft} 2s ease-in-out infinite;
  }
  &::after {
    content: ' -';
    display: inline-block;
    margin-left: 0.1em;
    animation: ${moveRight} 2s ease-in-out infinite;
  }
  
`;