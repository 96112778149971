import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"

const Meta = ({
  type,
  title = undefined,
  image,
  description,
  uri
}) => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `
  )

  const dataSiteMetadata = data.site.siteMetadata;

  const url = uri ? `${dataSiteMetadata.siteUrl}${uri}` : dataSiteMetadata.siteUrl;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:type" content={type ? type : `website`} />
      <meta property="og:site_name" content={dataSiteMetadata.title} />
      <meta property="og:title" content={title} />
      {image &&
        <meta name="image" property="og:image" content={`${dataSiteMetadata.siteUrl}${image}`} />
      }
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={url} />
      {image &&
        <meta name="twitter:image" content={`${dataSiteMetadata.siteUrl}${image}`} />
      }
      {/*<meta name="twitter:site" content={dataSiteMetadata.twitterSite} />*/}
    </Helmet>
  );
};

export default Meta;