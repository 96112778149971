import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Video from "./Video";
import ArrowRight from "./ArrowRight";
import { getMuralSlug } from "../helpers/getMuralSlug";
import { getMuralArtistsNames } from "../helpers/getMuralArtistNames";

const MuralThumb = ({item}) => {
  const image = getImage(item.image.imageFile.childImageSharp.gatsbyImageData);
  return (
    <StyledMuralThumb>
      <Link to={`/murals/${getMuralSlug(item)}`} title={`${item.title} by ${getMuralArtistsNames(item)}`}>
        <figure>
          {item.video_cover_url ? (
            <Video
              src={item.video_cover_url}
              muted="muted"
              autoplay="autoplay"
              loop="loop"
              preload="preload"
              fitToContainer="true"
            />
          ) : (
            <GatsbyImage image={image} alt="" />
          )}
        </figure>
        <div>
          <div>
            <h1><strong>{item.title}</strong> by {getMuralArtistsNames(item)}</h1>
            <small>({item.country})</small>
          </div>
          <ArrowRight size="1.5" />
        </div>
      </Link>
    </StyledMuralThumb>
  );
};

export default MuralThumb;

const StyledMuralThumb = styled.article`
  position: relative;
  height: 125vh;
  > a {
    position: sticky;
    top: 0;
    display: block;
    height: 100vh;
  }
  
  figure {
    position: relative;
    left: 50%;
    margin-left: -50vw;
    width: 100vw;
    .gatsby-image-wrapper {
      position: absolute !important;
      top: 0;
      left: 0;
      height: 100% !important;
      width: 100% !important;
    }
  }
  a > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h1 {
    font-size: 1.5em;
    font-weight: 500;
    margin: 0;
    line-height: 1.2;
    strong {
      text-transform: uppercase;
      font-weight: inherit;
    }
  }
  small {
    display: block;
    font-size: 1em;
    text-transform: uppercase;
    margin: 0.5em 0 0;
    line-height: 1;
  }
  @media ( max-width: 48em ) {
    a {
      display: grid;
      grid-template: 1fr 1fr / 1fr;
      .can-hover & > div:hover,
      &:active > div {
        opacity: 0.5;
      }
    }
    h1 strong {
      display: block;
    }
  }
  @media ( min-width: 48em ) {
    figure {
      position: absolute;
      top: 0;
      bottom: 0;
    }
    h1 {
      font-size: 1.5em;
      line-height: 1;
    }
    a > div {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--color-background);
      padding: 2.125em 2em 2em;
      .can-hover &:hover > * {
        opacity: 0.5;
      }
    }
    a:active > div > * {
      opacity: 0.5;
    }
  }
  @media ( min-width: 60em ) {
    a > div {
      left: calc(50% - 2em);
      width: calc(50% + 2em);
      
    }
  }
`;